<template lang="pug">
    .category-icon.py-3
      a(:href="'/search/'+slug").no-decoration
        .d-flex.flex-column.justify-content-center
          img.image(:src="getIconForCategory()")
          .bold.cat-title.text-center.mt-1 {{name}}
</template>

<script>

export default {
  name: 'CategoryIcon',
  props: {
    name: String,
    slug: String
  },
  methods:{
    getIconForCategory(){
        try {
            return require('@/assets/categories_icons/'+this.slug+'.svg') ?? '';
        } catch (e) {
            return ''
        }
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../../general.less';
    .cat-title {
      text-transform: uppercase;
    }
    .image {
      max-height: 50px;
    }
    .no-decoration {
      text-decoration: none;
      * {
        text-decoration: none;
        color: black;
      }
    }
</style>
