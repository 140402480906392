<template lang="pug">
    .vehicle-box-medium.px-4
      router-link(:to="{name: 'vehicle', params:{id: vehicle.id}}" v-if="vehicle").link-no-decoration
        .box-container
          img.box-image.col-sm-12.car-img(:src="image(vehicle)")
          .box-description.col-sm-12.my-4.px-4
            .row.mt-1
              .col 
                i(:class="'car-'+vehicle.get('make').toLowerCase().replace(' ', '-')").car-logo
            .row.mt-3
              .col-12.bold.title {{vehicle.get("make")}} {{vehicle.get("model")}} {{vehicle.get("version")}}
              .col-12.regular.price {{euro(vehicle.get("customers_price"))}}
            hr
            .row
              .col-6 
                .small.bold KM
                .info.regular {{vehicle.get("km").toLocaleString('it-IT')}}
              .col-6 
                .small.bold Mese/anno
                .info.regular {{vehicle.get("registration_month")}}/{{vehicle.get("registration_year")}} 
</template>

<script>
import {currencyFormatter, getMainImage} from '../../tools'

export default {
  name: 'VehicleBoxMedium',
  props: {
    vehicle: Object
  },
  methods:{
    euro(value){
      return currencyFormatter.format(parseFloat(value))
    },
    image(vehicle){
      return getMainImage(vehicle, 'hd')
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../../general.less';
    .car-logo {
      font-size: 25px;
    }
    .box-container {
      overflow: hidden;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
      // height: 200px;
      border-radius: 10px;
      .title {
        font-size: 20px;
      }
      .price {
        font-size: 20px;
      }
      .info {
        font-size: 16px;
      }

      .box-image {
        background-color: lightgray;
      }
      .car-img {
        background: white;
        background-size: cover;
        background-position: center;
        max-width: 100%;
      }
    }
</style>
