<template lang="pug">
  #app
    Navigation(@togglemenu="toggleMenu()")
    router-view()
    Footer()
    .mobile-menu.w-100.h-100(v-if="isMobileMenuVisible")
      .d-flex.align-items-end.justify-content-end
        .close-button.p-4(@click="toggleMenu()")
          font-awesome-icon(icon="times").close-icon
      .d-flex.align-items-center.justify-content-center.mt-5
        ul.navbar-nav.align-items-center(@click="toggleMenu()")
          li.nav-item
            router-link(:to='{name: "home"}').nav-link Vetrina
          li.nav-item
            router-link(:to='{name: "search"}').nav-link Catalogo
          li.nav-item
            router-link(:to='{name: "sell"}').nav-link Vendi
          li.nav-item
            router-link(:to='{name: "who"}').nav-link Chi siamo
          li.nav-item
            router-link(:to='{name: "contacts"}').nav-link
              .primary-button Richiedi informazioni
    .mobile-bottom-fixed(v-if="fixedContacts()").w-100
      .d-flex.justify-content-center
        a.no-decoration(href="tel:0522889888").d-flex.flex-column.align-items-center.px-4.py-3
            .icon 
              font-awesome-icon(icon="phone")
            .text CHIAMA SUBITO
        a.no-decoration(href="mailto:info@okaycars.it").d-flex.flex-column.align-items-center.px-4.py-3
            .icon 
              font-awesome-icon(icon="envelope")
            .text INVIA MAIL
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import {Navigation, Footer} from './components'
export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  },
  data(){
    return {
      isMobileMenuVisible: false
    }
  },
  methods: {
    toggleMenu(){
      this.isMobileMenuVisible = !this.isMobileMenuVisible
    },
    fixedContacts(){
      return isMobile
    }
  }
}
</script>

<style lang="less">
  @import "general.less";
  .riga {
    border: 1px solid white;
  }
  .mobile-bottom-fixed {
    // height: 80px;
    background: @secondary;
    color: white;
    position: fixed;
    bottom: 0;
  }
  .mobile-menu {
    background-color: rgba(0, 0, 0, 0.95);
    top:0;
    position: absolute;
    .nav-link {
      color: white;
      font-size: 24px;
    }
    z-index: 1001;
  }

  .close-icon {
    font-size: 25px;
    color:white
  }
</style>
