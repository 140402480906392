<template lang="pug">
.footer.w-100
  .container
    .reviews
      .review-component.border-top
        h4.mt-5.bold.text-center I nostri clienti dicono di noi...
        .d-flex.justify-content-between
          .arrow.d-flex.align-items-center.d-none.d-md-flex
            .icon.z-1(@click="previousPage()") <font-awesome-icon icon = "chevron-left" />
          carousel.carousel.flex-grow-1(
            ref="reviews",
            :perPage="1",
            :loop="true",
            :autoplay="true",
            :autoplayTimeout="7000"
          )
            slide(v-for="(review, index) in reviews", :key="review.id")
              SingleReview(:review="review")
          .arrow.d-flex.align-items-center.d-none.d-md-flex
            .icon.z-1(@click="nextPage()") <font-awesome-icon icon = "chevron-right" />
  .black-footer.py-4.mt-3
    .container.newsletter.py-3
      .row
        .col-12.col-md-4.mt-5
          h4.bold Iscriviti alla nostra newsletter!
          h5.light Ricevi ogni mese le novità della concessionaria.
          .row
            .col-12
              .d-flex.flex-column
                label.bold.form-group.mt-2(for="email") La tua email
                input.form-group.newsletter-input.mt-1(
                  type="email",
                  v-if="!message",
                  name="email",
                  v-model="email"
                )
                .mt-3
                  input(type="checkbox" v-model="checkPrivacy") 
                  span.mx-2 Dichiaro di aver letto 
                      router-link(to="privacy-policy" target="_blank") l'informativa
                      |  e, con specifico riferimento alle finalità, presto consenso al trattamento dei dati
                button.primary-button.mt-2.text-center(
                  @click="addSubscriber",
                  v-if="!message"
                  :disabled="!checkPrivacy" 
                  :class="!checkPrivacy ? 'opacity-50' : ''"
                )
                  span.bold ISCRIVITI
                p.small-text.mt-2(v-if="message") {{ message }}
                p.small-text.mt-2(v-if="errorMessage") {{ errorMessage }}
        .col-12.col-md-4.mt-5
          h4.bold Orari di apertura
          h5.light Vienici a trovare in concessionaria
          .row
            .col-12
              div Aperti tutti i giorni <b>dalle 9.00 alle 13.00</b> e <b>dalle 14.30 alle 19.00</b>
              .mt-1
                b Chiuso il sabato pomeriggio e la domenica.
        .col-12.col-md-4.mt-5
          h4.bold Contattaci
          h5.light Puoi telefonarci o scriverci!
          .row
            .col-12
              .d-flex
                a.no-decoration(href="tel:0522889888")
                  .primary-button.mt-2 
                    font-awesome-icon(icon="phone") 
                    b  0522 889888 
              p.mt-3 Oppure scrivi a 
                a.no-decoration(href="mailto:info@okaycars.it")
                  b info@okaycars.it
    .container
      hr
      .mt-5.py-5.row 
        .col-md-4
          h5.bold OkayCars s.r.l
          p Via G. di Vittorio 41 <br>
            | Puianello di Quattro Castella <br>
            | 42020 Reggio Emilia

          p.mt-1 C.F. e P.IVA: 02555980354
          .mt-3.d-flex.flex-row
            a.iubenda-white.no-brand.iubenda-embed(
              href="https://www.iubenda.com/privacy-policy/29055755",
              title="Privacy Policy"
            ) Privacy Policy
            a.iubenda-white.no-brand.iubenda-embed(
              href="https://www.iubenda.com/privacy-policy/29055755/cookie-policy",
              title="Cookie Policy"
            ) Cookie Policy
        .col-md-4
          .bold Sitemap
          ul
            li Vetrina
            li Catalogo
            li Vendi
            li Chi siamo
            li Contatti
        .col-md-4
          .bold Social Network
            .row.mt-4
              .col-12 
                a(
                  target="_blank",
                  href="https://www.facebook.com/okaycarssrl/"
                )
                  img.socialImg.mx-3(:src="facebookImage")
                a(
                  target="_blank",
                  href="https://www.instagram.com/okaycarssrl/"
                )
                  img.socialImg.mx-3(:src="instagramImage")
                a(
                  target="_blank",
                  href="https://www.linkedin.com/in/roberto-colli-156b813a"
                )
                  img.socialImg.mx-3(:src="linkedinImage")
</template>

<script>
import Parse from "parse";
import SingleReview from "./single-review";
export default {
  name: "Footer",
  components: {
    SingleReview,
  },
  mounted() {
    this.loadReviews();
  },
  data() {
    return {
      reviews: [],
      email: "",
      message: null,
      errorMessage: null,
      checkPrivacy: false
    };
  },
  methods: {
    loadReviews() {
      const Review = Parse.Object.extend("Review");
      const query = new Parse.Query(Review);
      query.find().then(
        (reviews) => {
          this.reviews = reviews;
          console.log(this.reviews);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    nextPage() {
      this.$refs.reviews.handleNavigation("forward");
    },
    previousPage() {
      this.$refs.reviews.handleNavigation("backward");
    },
    addSubscriber(e) {
      e.preventDefault()
      
      this.errorMessage = null
      if (!this.validate()) {
        this.errorMessage = "E-mail non valida";
      }
      Parse.Cloud.run("addSubscriber", { email: this.email }).then(() => {
        this.message = "E-mail aggiunta alla newsletter!";
      });
    },
    validate() {
      return this.email != "" && this.validateEmail(this.email);
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
  computed: {
    facebookImage() {
      return require("@/assets/facebook.svg");
    },
    instagramImage() {
      return require("@/assets/instagram.svg");
    },
    linkedinImage() {
      return require("@/assets/linkedin.svg");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../general.less";
.black-footer {
  background-color: #000;
  color: white;
}
.subtitle-review {
  font-size: 35px;
  font-weight: 600;
}
.socialImg {
  width: 40px;
}
.border-top {
  border-top: solid 1px @primary;
  padding-top: 40px;
}
.review-component {
  margin-top: 10px;
}

.carousel-inner {
  height: 200px;
  object-fit: cover;
}

.carousel {
  max-width: 100%;
}

.carousel-item {
  /* background: lightgray; */
  // height: 100%;
}

.carousel-control-next-icon {
  // background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231F556D' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  background: url("../../assets/next-arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.carousel-control-prev-icon {
  // background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231F556D' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  background: url("../../assets/prev-arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.newsletter-input {
  min-height: 40px;
  border: 1px white solid;
  background: transparent;
  color: white;
  border-radius: 4px;
}
</style>
