<template lang="pug">
    .category-box
        a(:href="'/search/'+slug").no-decoration
            .box-with-img(:style="{backgroundImage: 'url('+getImageForCategory()+')'}")
                .w-100.h-100.d-flex.align-items-center.justify-content-center(:class="(name=='NEOPATENTATI') ? '' : 'box-dark-layer'")
                    .box-title.bold {{name}}        
</template>

<script>
export default {
  name: 'CategoryBox',
  props: {
      name: String,
      slug: String
  },
  methods:{
    getImageForCategory(){
        try {
            return require('@/assets/'+this.slug+'.webp') ?? '';
        } catch (e) {
            return ''
        }
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../../general.less';
    .box-with-img {
        width: 100%;
        height: 150px;
        background-color:lightgray;
        background-size: cover;
        background-position: center;
        cursor: pointer;
    }

    .box-dark-layer {
        background-color: rgba(0,0,0,0.5);
    }
    .box-title {
        color: white;
        font-size: 20px;
    }
</style>
