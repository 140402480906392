<template lang="pug">
    .single-review-component.d-flex.flex-column.justify-content-center.align-items-center.py-2
        .stars
            img(v-for="(star, index) in stars", :src="getStar(index)").star
        .name.p-3.bold.text-center {{this.review.get("completeName")}}
        .text.small-text.text-center {{this.review.get("description")}}
</template>

<script>
export default {
  name: 'SingleReview',
  props: {
    review: Object
  },
  data () {
    return {
        stars: 5
    }
  },
  mounted () {
  },
  methods: {
    getPic(name) { 
        return require(`@/assets/${name}.png`); 
    },
    getStar(index){
        if(index < this.review.get("rating")) return this.getPic("review-star-filled")
        return this.getPic("review-star-empty")
    }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>  
  .star {
      max-height: 20px;
  }
  .single-review-component {
      height: 100%;
  }

  .text {
      max-width: 80%;
  }
</style>
