<template lang="pug">
    .sell
        .carousel(:style="{backgroundImage: 'url('+getCarouselImage(1)+')'}")
        .container
            .row.py-5.mt-3
                .col-12.col-md-7.mt-2
                    h4.bold Molto più che una semplice vetrina
                    p.mt-2 La Famiglia Colli, dal 1983 titolare dell’attività, ancora oggi a gestione familiare, vanta più di 45 anni di esperienza nel mondo dell’auto.

                    p La nuova sede si sviluppa su 1500 metri quadrati di esposizione coperta, più l’ampio piazzale di mostra all’aperto. Inaugurata nel 2003, è stata pensata appositamente per dare ai nostri clienti il servizio e il trattamento adeguati.

                    p Trattiamo autovetture, suv, e fuoristrada, nuovi e usati d’ogni marca e modello. Il nostro vanto sono tuttavia le auto seminuove dal chilometraggio molto limitato e dalle condizioni pari al nuovo.
                    p Da anni ci riforniamo da mercati nazionali ed esteri, ciò ci permette di poter garantire sempre un vasto assortimento.

                    p La soddisfazione del nostro cliente è l’obbiettivo prioritario. Seguiamo la pratica auto dall’inizio alla fine della vendita, sia nella parte più burocratica che in quella legale.

                    p Da anni siamo apprezzati per la cura nei minimi particolari che abbiamo per le nostre auto, sia dal punto di vista estetico che da quello meccanico, e per il trattamento personalizzato che riserviamo ad ogni nostro cliente.

                    p Qualità, professionalità e serietà sono le parole chiave con le quali ogni giorno svolgiamo con passione il nostro lavoro, e con cui speriamo ci possa identificare ogni nostro cliente.
                .col-0.col-md-1
                .col-12.col-md-4.mt-2
                    Searchbox()
</template>
<script>
import {Searchbox} from '../components'
export default {
  name: 'Who',
  components: {
      Searchbox
  },
  methods: {
    getCarouselImage(index){
        return require('@/assets/'+index+'.webp');
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../general.less';
    .carousel {
        height: 40vh;
        background-color: @temp;
        background-position: center;
        background-size: cover;
    }
</style>
