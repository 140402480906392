<template lang="pug">
    .search
      div(v-if="category")
        .hero-img(:style="{backgroundImage: 'url('+getImageForCategory()+')'}")
          .hero-content.d-flex.align-items-center.justify-content-center
            .hero-text {{camelize(category.replace('-', ' '))}}
      .container
        div(v-if="!category")
          h4.mt-5.bold.text-center Cerca la tua prossima auto nel nostro catalogo!
          h5.light.text-center Esplora tra la nostra selezione
        .row.mt-5
          .col-lg-3.col-sm-12
              .search-params-content
                .row(v-if="!collapsed")
                  .col-12.d-flex.flex-column.mt-3(v-if="!category")
                      label.bold.mb-2 Tipo
                      select.form-control(v-model="selected.type" @change="selected.make = ''; selected.model = ''")
                        option(value="") Tutti
                        option(value="car") Auto
                        option(value="motorbike") Moto
                        option(value="camper") Camper
                  .col-12.d-flex.flex-column.mt-3
                      label.bold.mb-2 Marca
                      select.form-control(v-model="selected.make" @change="selected.model = ''")
                        option(value="") Tutti
                        option(:value="n" v-for="n in selectable.makes") {{n}}
                  .col-12.d-flex.flex-column.mt-3
                      label.bold.mb-2 Modello
                      select.form-control(v-model="selected.model")
                        option(value="") Tutti
                        option(:value="n" v-for="n in models(selected.make)") {{n}}
                .row(v-if="!collapsed")
                    .col-12.d-flex.flex-column.mt-3
                        label.bold.mb-2 Anno
                        span
                          select.double-input.left.form-control(v-model="selected.year.from")
                            option(value="") da
                            option(:value="n" v-for="n in range(selectable.years.min, selectable.years.max)") {{n}}
                          select.double-input.right.form-control(v-model="selected.year.to")
                            option(value="") a
                            option(:value="n" v-for="n in range(selectable.years.min, selectable.years.max)") {{n}}
                    .col-12.d-flex.flex-column.mt-3
                        label.bold.mb-2 Km
                        span
                          select.double-input.left.form-control(v-model="selected.km.from")
                            option(value="") da
                            option(:value="n" v-for="n in range(selectable.km.min, selectable.km.max, selectable.km.step)") {{n.toLocaleString('it-IT')}}
                          select.double-input.right.form-control(v-model="selected.km.to")
                            option(value="") a
                            option(:value="n" v-for="n in range(selectable.km.min, selectable.km.max, selectable.km.step)") {{n.toLocaleString('it-IT')}}
                .row(v-if="!collapsed")
                    .col-12.d-flex.flex-column.mt-3
                        label.bold.mb-2 Alimentazione
                        select.form-control(v-model="selected.fuel")
                          option(value="") Tutti
                          option(v-for="fuel in selectable.fuels" :value="fuel") {{capitalize(fuel)}}
                    .col-12.d-flex.flex-column.mt-3
                        label.bold.mb-2 Trasmissione
                        select.form-control(v-model="selected.gearbox")
                          option(value="") Tutti
                          option(v-for="gearbox in selectable.gearboxes" :value="gearbox") {{capitalize(gearbox)}}
                .row(v-if="!collapsed")
                    .col.d-flex.justify-content-center.mt-3
                        button.primary-button.mt-2.w-100.text-center.bold.no-decoration(@click="findCars()") CERCA LA TUA AUTO
                .row(v-if="collapsed")
                    .col.d-flex.justify-content-center.mt-3
                        button.btn.primary-button.mt-2.w-100.text-center.bold.no-decoration(@click="collapsed=false") CERCA LA TUA AUTO
                .row.mt-5.d-none.d-lg-block
                  h5.mt-5.bold Esplora per categorie
                  //- CategoryBox(name="NEOPATENTATI", slug="neo-patentati").mt-3
                  //- CategoryBox(name="Station Wagon", slug="station-wagon").mt-3
                  //- CategoryBox(name="Suv", slug="suv").mt-3
                  //- CategoryBox(name="Coupè", slug="coupe").mt-3
                  .row
                    CategoryIcon(name="Station Wagon", slug="station-wagon").col-6.mt-3
                    CategoryIcon(name="Suv/Fuoristrada", slug="fuoristrada").col-6.mt-3
                    CategoryIcon(name="Monovolume", slug="monovolume").col-6.mt-3
                    CategoryIcon(name="4-5 porte", slug="4-5porte").col-6.mt-3
                    CategoryIcon(name="2-3 porte", slug="2-3porte").col-6.mt-3
                    CategoryIcon(name="Coupè", slug="coupe").col-6.mt-3
                    CategoryIcon(name="Moto", slug="moto").col-6.mt-3
                    CategoryIcon(name="Camper", slug="camper").col-6.mt-3
          .col-lg-1.col-sm-hidden
          .col-lg-7.col-sm-12
            .row.text-center.mt-5(v-if="isLoading")
              Loader()
            .row.text-center.mt-5(v-if="message")
              .col-12.p-4 {{message}}
            .serch-results.row(v-if="!isLoading")
              router-link(:to="{ name: 'vehicle', params: {id: 1 } }" v-for="car in cars").link-no-decoration.col-12.col-md-6
                VehicleBoxMedium(:vehicle="car").mt-4.vehicle-box
</template>

<script>
import {CategoryBox, VehicleBoxMedium, Loader, CategoryIcon} from './../components'
import { isMobile } from 'mobile-device-detect';
import Parse from 'parse'
import _ from 'lodash'
const Vehicle = Parse.Object.extend('Vehicle')
import VueRangeSlider from 'vue-range-component'
export default {
  name: 'Search',
  props: {
    category: String,
    preSelected: Object
  },
  components: {
    CategoryBox,
    CategoryIcon,
    VehicleBoxMedium,
    VueRangeSlider,
    Loader
  },
  data(){
    return {
      collapsed: false,
      isLoading: false,
      message: null,
      cars: [],
      selected: {
        type: "",
        model: "",
        make: "",
        year: {
          from: "",
          to: ""
        },
        km: {
          from: "",
          to: ""
        },
        fuel: "",
        gearbox: ""
      },
      selectable: {
        makes: [],
        makes_models: [],
        fuels: [],
        gearboxes: [],
        years: {
          min: 2000,
          max: new Date().getFullYear(),
        },
        km: {
          min: 0,
          max: 150000,
          step: 5000
        },
      }
    }
  },
  mounted(){
    if((this.preSelected || this.category) && isMobile){
      this.collapsed = true;
    }
    if(this.preSelected){
      this.selected = _.cloneDeep(this.preSelected)
    }
    this.findCars();
    this.prepareSearchData();
  },
  methods: {
    findCars(){
      this.isLoading = true;
      this.message = null;
      let query = new Parse.Query(Vehicle)
      if(this.selected.type != ""){
        query.equalTo('type', this.selected.type)
      }
      if(this.selected.make != ""){
        query.equalTo('make', this.selected.make)
      }
      if(this.selected.model != ""){
        query.equalTo('model', this.selected.model)
      }
      if(this.selected.fuel != ""){
        query.equalTo('fuel', this.selected.fuel)
      }
      if(this.selected.gearbox != ""){
        query.equalTo('gearbox', this.selected.gearbox)
      }
      if(this.selected.year.from != ""){
        query.greaterThanOrEqualTo('registration_year_number', this.selected.year.from)
      }
      if(this.selected.year.to != ""){
        query.lessThanOrEqualTo('registration_year_number', this.selected.year.to)
      }
      if(this.selected.km.from != ""){
        query.greaterThanOrEqualTo('km', this.selected.km.from)
      }
      if(this.selected.km.to != ""){
        query.lessThanOrEqualTo('km', this.selected.km.to)
      }
      if(this.category){
        switch(this.category){
          case 'station-wagon': 
            query.equalTo('body', 'Station Wagon')
            break;
          case 'coupe':
            query.equalTo('body', 'Coupè')
            break;
          case 'fuoristrada':
            query.equalTo('body', 'Fuoristrada')
            break;
          case 'monovolume':
            query.equalTo('body', 'Monovolume')
            break;
          case '2-3porte':
            query.equalTo('body', '2/3-Porte')
            break;
          case '4-5porte':
            query.equalTo('body', '4/5-Porte')
            break;
          case 'moto':
            query.startsWith('body', 'Moto')
            break;
          case 'camper':
            query.startsWith('body', 'Camper')
            break;
          case 'neo-patentati':
            query.equalTo('occasion', true)
            break;
        }
      }

      query.descending("createdAt");
      query.find()
      .then((cars) => {
        this.cars = cars
        this.isLoading = false
        if(cars.length == 0){
          this.message = 'Nessun risultato trovato, riprova con un altra ricerca!'
        }
      })
      .catch(function(e){
        console.log(e)
        this.message = 'Nessun risultato trovato, riprova con un altra ricerca!'
      })
    },
    prepareSearchData(){
      Parse.Cloud.run('getMakesAndModels')
      .then((response) => {
        this.selectable.makes = response.makes
        this.selectable.makes_models = response.makes_models
        this.selectable.fuels = response.fuels
        this.selectable.gearboxes = response.gearboxes
      })
    },
    models(make){
      if(!make || make == "") return []
      return this.selectable.makes_models.filter((item) => {
        if(item.name == make) return true;
      })[0].models
    },
    capitalize(str){
      return _.capitalize(str)
    },
    camelize(str){
      return _.startCase(str)
    },
    range(start, end, step = 1) {
      // if(start === end) return [start];
      // return [start, ...this.range(start + 1, end)];
      return Array(Math.ceil((end - start) / step) + 1).fill(start).map((x, y) => x + y * step)
    },
    getImageForCategory(){
      try {
          return require('@/assets/'+this.category+'.webp') ?? '';
      } catch (e) {
          return ''
      }
    }
  },
  computed: {
  },
  beforeRouteUpdate(to, from, next) {
    this.category = to.params.category;
    next();
  }
}
</script>

<style lang="less" scoped>
    @import '../general.less';
    .search-params-content {
        .form-control {
            background-color: transparent;
            border: 1px solid;
            border-radius: 3px;
            height: 50px;
            font-size: 18px;
        }
    }

    .hero-img {
      height:40vh;
      background-size: cover;
      background-position: center;
    }
    .hero-content {
      background-color: rgba(0,0,0,0.5);
      width: 100%;
      height:100%;
    }
    .hero-text{
      color: white;
      font-size: 35px;
      font-weight: bold;
    }

    .double-input {
      width: 50%;
      display: inline-block;
    }
    
    .double-input.left {
      border-radius: 3px 0px 0px 3px !important;
    }

    .double-input.right {
      border-radius: 0px 3px 3px 0px !important;
    }
</style>
