<template lang="pug">
    .vehicle-box-small.my-5
      a(:href="'/vehicle/' + vehicle.id" v-if="vehicle").link-no-decoration
        img.car-img(:src="image(vehicle)")
        h5.bold.mt-2 {{vehicle.get("make")}} {{vehicle.get("model")}} {{vehicle.get("version")}}
        .row
          .col-6
             span.bold Mese/anno: 
             span {{vehicle.get("registration_month")}}/{{vehicle.get("registration_year")}}
          .col-6 
            span.bold KM: 
            span {{vehicle.get("km").toLocaleString('it-IT')}}
      div.car-img(v-if="!vehicle").wow.fadeIn
</template>

<script>
import {currencyFormatter, getMainImage} from '../../tools'
export default {
  name: 'VehicleBoxSmall',
  props: {
    vehicle: Object
  },
  methods: {
    euro(value){
      return currencyFormatter.format(parseFloat(value))
    },
    image(vehicle){
      return getMainImage(vehicle, 'hd')
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../../general.less';
    .car-img {
      // height: 12vw;
      @media only screen and (max-width: 991px){
        // height: 280px;
      }
      @media only screen and (max-width: 450px){
        // height: 50vw;
      }
      width: 100%;
      background: lightgrey;
      background-size: contain;
      background-position: center;
      background-position-x: center;
    }
</style>
