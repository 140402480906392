<template lang="pug">
    .homepage
        .desktop
            .hero
                .d-flex.align-items-center.title-container
                    //- .title Professionalità, serietà e <br>qualità dal 1983
                //- carousel(:perPage="1" :autoplay="true" :loop="true" :paginationEnabled="false" :autoplayTimeout="5000").z-1
                //-     slide(v-for="n in 9")
                //-         .carousel(:style="{backgroundImage: 'url('+getCarouselImage(n)+')'}")
                //-             .backgroundLayer.w-100.h-100
                .video
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/9qdGkw9iv-0?si=4kIuGt_dNCyb37Db&amp;controls=0&autoplay=1&mute=1&loop=1&showinfo=0&playlist=9qdGkw9iv-0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            //- .searchbox-container.d-flex.align-items-end
        Searchrow().d-none.d-lg-block.searchrow
        Searchbox(:collapsed="true").d-block.d-lg-none
        //- .mobile.d-block.d-lg-none
            .hero
                .title-mobile Professionalità, serietà e <br>qualità dal 1983
                carousel(:perPage="1" :autoplay="true" :paginationEnabled="false" :autoplayTimeout="7000").z-1
                    slide(v-for="n in 9")
                        .carousel-mobile(:style="{backgroundImage: 'url('+getCarouselImage(n)+')'}")
                            .backgroundLayer.w-100.h-100
            Searchbox(:collapsed="true").searchbox.ms-auto
        .container.mt-5
            .small-title Perchè scegliere noi
            .subtitle Siamo rivenditori multimarca a Reggio nell’Emilia e provincia. <br>Da noi trovi veicoli selezionati di tutte le marche. 
        .container.mt-3
            h4.mt-5.bold.text-center Trova l'auto giusta per te!
            h5.light.text-center Esplora il nostro catalogo

            .categories.row.my-4
                .d-flex.justify-content-between.mt-5
                    .arrow.d-flex.align-items-center.d-none.d-md-flex(@click="previousPage()")
                        .icon.z-1 <font-awesome-icon icon = "chevron-left" /> 
                    carousel.categories(ref="categories" :perPage="getPages()" :autoplay="false" :autoplayTimeout="7000").flex-grow-1
                        slide
                            CategoryIcon(name="Station Wagon", slug="station-wagon")
                        slide
                            CategoryIcon(name="SUV/Fuoristrada", slug="fuoristrada")
                        slide
                            CategoryIcon(name="Monovolume", slug="monovolume")
                        slide
                            CategoryIcon(name="4-5 porte", slug="4-5porte")
                        slide
                            CategoryIcon(name="2-3 porte", slug="2-3porte")
                        slide
                            CategoryIcon(name="Coupè", slug="coupe")
                        slide
                            CategoryIcon(name="Moto", slug="moto")
                        slide
                            CategoryIcon(name="Camper", slug="camper")
                    .arrow.d-flex.align-items-center.d-none.d-md-flex(@click="nextPage()")
                        .icon.z-1 <font-awesome-icon icon = "chevron-right" />                     
        .container.mt-5
            h4.mt-3.bold.text-center Ultimi arrivi
            .row.mt-4
                VehicleBoxSmall(v-if="newVehicles" v-for="vehicle in newVehicles", :vehicle="vehicle").col-lg-4.col-sm-12.px-3.mt-3
                VehicleBoxSmall(v-if="!newVehicles" v-for="n in 6").col-lg-4.col-sm-12.px-3.mt-3

            div(v-if="inEvidence").py-5
                h4.mt-5.bold.text-center In evidenza!
                h5.light.text-center Il meglio della nostra vetrina
                    VehicleBoxBig(:vehicles="inEvidence").mt-5
</template>

<script>
import Parse from 'parse'
import {isMobile} from 'mobile-device-detect'
const Vehicle = Parse.Object.extend("Vehicle");
import {Searchbox, Searchrow, VehicleBoxSmall, VehicleBoxBig, CategoryBox, CategoryIcon} from '../components'
export default {
  name: 'Homepage',
  components: {
      Searchbox,
      Searchrow,
      VehicleBoxSmall,
      CategoryBox,
      VehicleBoxBig,
      CategoryIcon
  },
  data(){
      return {
          newVehicles: null,
          loading: false,
          inEvidence: null
      }
  },
  mounted(){
    this.loadVehicles();
    this.loadInEvidence();
  },
  methods:{
    loadVehicles() {
    this.loading = true
    const vehiclesQuery = new Parse.Query(Vehicle);
    vehiclesQuery
        .equalTo('new_vehicle', true)
        .descending('createdAt')
        .limit(6)
        .find()
        .then((data) => {
            this.newVehicles = data;
            this.loading = false
        })
        .catch((error) => {
            console.log(error)
            this.loading = false
        });
    },
    loadInEvidence() {
        this.loading = true
        const vehiclesQuery = new Parse.Query(Vehicle);
        vehiclesQuery
            .equalTo('in_evidence', true)
            .find()
            .then((data) => {
                this.inEvidence = data;
                console.log(this.inEvidence)
                this.loading = false
            })
            .catch((error) => {
                console.log(error)
                this.loading = false
            });
    },
    getCarouselImage(index){
        return require('@/assets/'+index+'.webp');
    },
    nextPage(){
        console.log('here')
      this.$refs.categories.handleNavigation('forward');
    },
    previousPage(){
      this.$refs.categories.handleNavigation('backward');
    },
    getPages(){
        if(!isMobile){
            return 5
        } else {
            return 2
        }
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../general.less';
    .video {
        height: 60vw;
        margin-top: -64px;
    }
    .z-1 { 
        z-index: -1;
    }
    .small-title {
        font-size: 25px;
        font-weight: bold;
        text-align: center;
    }
    .subtitle {
        font-size: 25px;
        font-weight: 300;
        text-align: center;
    }
    .title {
        color: white;
        font-size: 4vw;
        font-weight: bold;
        line-height: 4vw;
    }
    .arrow {
        cursor:pointer;
        min-width: 30px;
    }

    .title-container {
        position: absolute;
        z-index: 1000;
        height: 60vh;
        margin-top: 260px;
        padding: 70px;
    }

    .banner {
      @media only screen and (min-width: 451px){
          margin-top: -30px !important;
      }
    }

    .title-mobile {
        position: absolute;
        z-index: 1000;
        color: white;
        text-align: center;
        font-size: 8vw;
        font-weight: bold;
        line-height: 8vw;
        padding: 40px;
    }

    .carousel {
        height: 50vh;
        @media only screen and (max-width: 450px){
            height:90vh;
            filter: blur(2px);
            -webkit-filter: blur(2px);
        }
        background-color: @temp;
        margin-top: -@navbar-height;
        background-position: center;
        background-size: cover;
    }
    .carousel-mobile {
        height: 40vh;
        background-color: @temp;
        margin-top: -@navbar-height;
        background-position: center;
        background-size: cover;
       
    }
    @media (orientation: landscape) {
        .carousel-mobile {
            height: 70vh;
        }
    }
     @media (max-width: 991px) {
        .banner {
          margin-top: 20px !important;
        }
    }
    .searchbox-container {
        margin-top: -27vh;
        margin-right: 5vw;
        @media only screen and (max-width: 450px){
            margin-top: -75vh;
            margin-left: 5vw;
        }
    }

    .backgroundLayer {
        background-color: rgba(0,0,0,0.6);
    }

    .categories {
        max-width: 100%;
    }
</style>
