const currencyFormatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR'
})

const getMainImage = (vehicle, size) => {
    return vehicle.get('images')[0][size].url()
}

module.exports = {
    currencyFormatter: currencyFormatter,
    getMainImage: getMainImage
}


