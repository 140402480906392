<template lang="pug">
.content
    .row
        .col-12.col-md-2.d-flex.flex-column.mt-2
            label.bold.mb-2 Marca
            select(v-model="selected.make" @change="selected.model = ''")
                option(value="") Tutti
                option(:value="n" v-for="n in selectable.makes") {{n}}
        .col-12.col-md-2.d-flex.flex-column.mt-2
            label.bold.mb-2 Modello
            select(v-model="selected.model")
                option(value="") Tutti
                option(:value="n" v-for="n in models(selected.make)") {{n}}
        .col-12.col-md-2.d-flex.flex-column.mt-2
            label.bold.mb-2 Alimentazione
            select(v-model="selected.fuel")
                    option(value="") Tutti
                    option(v-for="fuel in selectable.fuels" :value="fuel") {{capitalize(fuel)}}
        .col-12.col-md-2.d-flex.flex-column.mt-2
            label.bold.mb-2 Km
            span
                select.double-input.left(v-model="selected.km.from")
                    option(value="") da
                    option(:value="n" v-for="n in range(selectable.km.min, selectable.km.max, selectable.km.step)") {{n.toLocaleString('it-IT')}}
                select.double-input.right(v-model="selected.km.to")
                    option(value="") a
                    option(:value="n" v-for="n in range(selectable.km.min, selectable.km.max, selectable.km.step)") {{n.toLocaleString('it-IT')}}
        .col-12.col-md-2.d-flex.justify-content-center.align-items-end 
            router-link(:to="{name: 'search', params:{'preSelected': selected}}").primary-button.mt-2.w-100.text-center.bold.no-decoration
                | CERCA LA TUA AUTO
        .col-12.col-md-2.d-flex.justify-content-center.align-items-end 
            a(href="/search/neo-patentati").no-decoration.white.secondary-button.mt-2.w-100.text-center.bold.no-decoration AUTO NEOPATENTATI
                    
</template>

<script>
import Parse from 'parse'
import _ from 'lodash'
export default {
  name: 'Searchrow',
  props: {
    collapsed: Boolean
  },
  data(){
      return {
          isLoading: false,
          selected: {
            type: "",
            model: "",
            make: "",
            year: {
            from: "",
            to: ""
            },
            km: {
            from: "",
            to: ""
            },
            fuel: "",
            gearbox: ""
        },
        selectable: {
            makes: [],
            makes_models: [],
            fuels: [],
            gearboxes: [],
            years: {
            min: 2000,
            max: new Date().getFullYear(),
            },
            km: {
            min: 0,
            max: 150000,
            step: 5000
            },
        }
      }
  },
  mounted(){
      this.prepareSearchData();
  },
  methods: {
    prepareSearchData(){
      this.isLoading = true;
      Parse.Cloud.run('getMakesAndModels')
      .then((response) => {
        this.selectable.makes = response.makes
        this.selectable.makes_models = response.makes_models
        this.selectable.fuels = response.fuels
        this.selectable.gearboxes = response.gearboxes
        this.isLoading = false;
      })
    },
    models(make){
      if(!make || make == "") return []
      return this.selectable.makes_models.filter((item) => {
        if(item.name == make) return true;
      })[0].models
    },
    range(start, end, step = 1) {
      // if(start === end) return [start];
      // return [start, ...this.range(start + 1, end)];
      return Array(Math.ceil((end - start) / step) + 1).fill(start).map((x, y) => x + y * step)
    },
    capitalize(str){
      return _.capitalize(str)
    },
  }
}
</script>

<style lang="less" scoped>
    @import '../../general.less';
    .content {
        color: @white;
        background: rgba(0,0,0,0.9);
        input {
            background-color: transparent;
            border: 1px solid @white;
            border-radius: 3px;
            height: 50px;
            font-size: 18px;
            color: black;
        }

        select {
            background-color: white;
            border: 1px solid @white;
            border-radius: 3px;
            height: 50px;
            font-size: 18px;
            color: black
        }
        padding:15px;
    }
    .double-input {
      width: 50%;
      display: inline-block;
    }
    
    .double-input.left {
      border-radius: 3px 0px 0px 3px !important;
    }

    .double-input.right {
      border-radius: 0px 3px 3px 0px !important;
    }
</style>
