import { render, staticRenderFns } from "./contacts.vue?vue&type=template&id=79d1f8ce&scoped=true&lang=pug&"
import script from "./contacts.vue?vue&type=script&lang=js&"
export * from "./contacts.vue?vue&type=script&lang=js&"
import style0 from "./contacts.vue?vue&type=style&index=0&id=79d1f8ce&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d1f8ce",
  null
  
)

export default component.exports