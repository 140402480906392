<template lang="pug">
    .sell
        .container
            .row.py-5.mt-3
                .col-12
                    h2.bold.text-center PRIVACY POLICY
                    h5.mt-5 Informativa ai sensi dell'art. 13 del Regolamento UE 679/2016 ("GDPR")
                    p Nell'osservanza del GDPR, OkayCars srl (dati specifici in fondo all’informativa), in qualità di "Titolare" del trattamento, fornisce informazioni circa l'utilizzo degli eventuali dati personali conferiti dagli utenti che consultano e/o interagiscono con il sito. Pertanto la presente informativa online sulla privacy si applica solo alle informazioni raccolte attraverso il nostro sito e non alle informazioni raccolte off-line.
                    
                    h5 Finalità del trattamento
                    p 
                        b Quali dati trattiamo e perché?
                        ul
                            li Quelli che tu ci invii dal nostro web form di contatto per richieste commerciali o semplici informazioni. Noi trattiamo questi dati al fine di produrti la migliore risposta. Questi dati possono essere trattati oltre che dai nostri lavoratori appositamente nominati ed autorizzati, dai nostri responsabili esterni: collaboratori, addetti alla manutenzione dei sistemi informativi aziendali e alla gestione amministrativa. Il trattamento dei tuoi dati personali è legittimo in quanto necessario per provvedere a fornirti il servizio che ci hai specificatamente richiesto e rientra nei trattamenti leciti ai sensi art. 6 comma 1 lettera B del Regolamento UE 2016/679 sulla Protezione dei Dati. Il rifiuto a questo trattamento comporta l’impossibilità da parte nostra di fornirti una risposta pertanto è impossibile inviare il modulo senza la tua spunta.
                            li I dati anagrafici e di contatto a fini di marketing e invio newsletter (comunicazione commerciale e promozionale) nel caso tu abbia scelto autonomamente, liberamente e consapevolmente di essere iscritto alla nostra newsletter tramite esplicito consenso. Questi dati possono essere trattati oltre che da noi da società terze specializzate nella gestione di informazioni commerciali, marketing, informatici e legali. Tutti questi soggetti esterni elencati operano in qualità di Responsabili esterni al trattamento dei dati opportunamente nominati. Il trattamento dei tuoi dati personali è legittimo in quanto conseguenza di un tuo esplicito consenso (mediante spunta sul modulo), ai sensi dell’art. 6 comma 1 lettera A del Regolamento UE 2016/679 sulla Protezione dei Dati. Nel caso tu non dovessi autorizzare questo tipo di trattamento non ci saranno pregiudizievoli alcuni sugli altri rapporti in essere o richiesti.
                    
                    h5 Modalità del trattamento
                    p 
                        b Strumenti e logiche 
                        br 
                        span In relazione alle finalità sopra richiamate, il trattamento avviene mediante strumenti manuali, informatici e telematici con logiche strettamente correlate alle finalità suddette e, comunque, in modo da garantire la sicurezza e la riservatezza dei dati stessi e con l'impegno da parte dell’utente di comunicarci sollecitamente eventuali correzioni, modificazione ed aggiornamenti. Detto trattamento potrà essere effettuato per conto del Titolare del Sito, per le finalità e con le modalità qui descritte e nel rispetto di criteri idonei a garantire sicurezza e riservatezza, da società, studi, enti e collaboratori esterni nominati Responsabili e solamente per quanto attiene ai trattamenti da loro effettuati.
                    
                    p 
                        b Durata
                        br
                        span I dati sono conservati per 10 anni per la finalità contrattuale/commerciale e per 5 anni per la finalità di marketing/newsletter dall’interruzione del rapporto commerciale o dall’inattività o, in caso di modifiche normative o di orientamento giurisprudenziale, fino a quando sia necessaria la conservazione per far valere un diritto di ambo le parti. E’ effettuata una periodica verifica sull’obsolescenza dei dati, nonché sulle e-mail non più utilizzate dall’interessato al trattamento nella comunicazione pubblicitaria con noi.
                    
                    p
                        b Diritti degli interessati
                        br
                        span Tutti i diritti di seguito menzionati possono essere esercitati tramite i dati di contatto del titolare al trattamento espressi a fine documento.
                        p 
                            ul 
                                li Diritto di richiedere al titolare del trattamento dei dati l’accesso ai propri dati personali
                                li Diritto di richiedere al titolare del trattamento la rettifica / la cancellazione / la limitazione relativamente ai propri dati personali
                                li Diritto di richiedere al titolare del trattamento l’opposizione relativamente al trattamento dei propri dati personali
                                li Diritto a ricevere l’elenco completo dei responsabili al trattamento dei dati (interni e esterni)
                                li Diritto di proporre reclamo a un’autorità di controllo come il Garante per la Privacy ai dati di contatto riportati al seguente link https://www.garanteprivacy.it/home/footer/contatti
                    p Non è presente alcun processo decisionale automatizzato, compresa la profilazione di cui all’articolo 22, paragrafi 1 e 4 del regolamento privacy europeo 2016/679 e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l’importanza e le conseguenze previste di tale trattamento per l’interessato.
                    
                    h5 Trasferimento dei dati all’estero
                    p Non sussistono trasferimenti esteri dei dati.
                    
                    h5 Titolari del trattamento dei dati personali
                    p Titolare del trattamento è OkayCars srl, con sede legale in via G. Di Vittorio, 41/A-B - 42020 Quattro Castella (RE), C.F. e P.I.V.A. 02555980354. E-mail: info@okaycars.it Telefono 0522 889888
</template>
<script>
export default {
  name: 'Priva',
  components: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
    @import '../general.less';
    .carousel {
        height: 40vh;
        background-color: @temp;
        background-position: center;
        background-size: cover;
    }
</style>
