<template lang="pug">
    .vehicle-detail
      .container.mt-5.text-center(v-if="loading")
        Loader
      .container.mt-3(v-if="vehicle")
        .row
          .carousel.col-lg-7.order-lg-1.order-1.mt-5
            div(:style="{backgroundImage:'url(' + mainImage(vehicle)+ ')'}", @click="galleryIndex = 0").img-fluid.wow.fadeIn
            //- img.img-fluid.w-100
            .image-list-container.mt-3
                div(v-for="(thumbImage, thumbIndex) in thumbImages", :key="thumbIndex", @click="galleryIndex = thumbIndex", :style="{backgroundImage: 'url(' + thumbImage.url + ')'}").thumb.image-list-item.wow.fadeIn
            hr
          .info.px-md-5.px-3.col-lg-5.order-lg-2.order-2.mt-5
            i.my-2(:class="'car-'+vehicle.get('make').toLowerCase().replace(' ', '-')").car-logo
            h4.bold {{vehicle.get("make")}} {{vehicle.get("model")}} {{vehicle.get("version")}}
            h4.regular {{euro(vehicle.get("customers_price"))}}
            .details
              .row.mt-5
                .col-6
                  .small Versione
                  .bold.side-info {{vehicle.get("version")}}
                .col-6
                  .small KM
                  .bold.side-info {{vehicle.get("km").toLocaleString('it-IT')}}
              .row.mt-2
                .col-6
                  .small Mese/anno
                  .bold.side-info {{vehicle.get("registration_month")}}/{{vehicle.get("registration_year")}}
                .col-6
                  .small Alimentazione
                  .bold.side-info {{vehicle.get("fuel")}}
              .row.mt-2
                .col-6
                  .small CC
                  .bold.side-info {{vehicle.get("cc")}}
                .col-6
                  .small Kw/Cv
                  .bold.side-info {{vehicle.get("kwatt")}} / {{calculateCv(vehicle.get("kwatt"))}}
              .row.mt-2
                .col-6
                  .small Trasmissione
                  .bold.side-info {{vehicle.get("gearbox")}}
                .col-6
                  .small Trazione
                  .bold.side-info {{capitalize(vehicle.get("traction"))}}
              .row.mt-2
                .col-6
                  .small Esterno
                  .bold.side-info {{vehicle.get("exterior_color")}}
                .col-6
                  .small Interno
                  .bold.side-info {{vehicle.get("interior_color")}}
              .row.mt-5
                .col-12
                  a(:href="'mailto:info@okaycars.it?subject=Richiesta informazioni per ' + vehicle.get('make') + ' ' + vehicle.get('model') + ' ' + vehicle.get('version')").no-decoration
                    span.px-4.py-3.primary-button.bold.text-center Richiedi subito informazioni
      
        //- Descriptions
        .row.mt-5
          .col-lg-7 
            .bold.subtitle(@click="showingDescription = !showingDescription")
              | Descrizione <font-awesome-icon v-if="showingDescription" icon = "chevron-down" /><font-awesome-icon v-if="!showingDescription" icon = "chevron-right" />
            .description-content.light(v-if="showingDescription") 
              .row.mt-4
                  .col-12
                    ul.two-cols
                      li(v-for="vehicleInfo in getMainInfos(vehicle)") {{vehicleInfo}}
              
              .row.mt-5
                .col-12.mt-4.bold Ulteriori informazioni
                .info.col-12(v-for="vehicleInfo in getLastInfos(vehicle)")
                  .mt-4(v-if="vehicleInfo != ''").italic
                    | {{vehicleInfo}}

            .mt-4
              .bold.subtitle(@click="showingEquipment = !showingEquipment")
                | Equipaggiamento/optionals <font-awesome-icon v-if="showingEquipment" icon = "chevron-down" /><font-awesome-icon v-if="!showingEquipment" icon = "chevron-right" />
              .description-content.light(v-if="showingEquipment") 
                .row.mt-4
                  .col-12
                    ul.two-cols
                      li(v-for="optional in getOptionals(vehicle)") {{optional}}
                  //- .info.col-6.col-md-4(v-for="optional in getOptionals(vehicle)")
                  //-   .mt-2(v-if="optional != ''")
                  //-     <font-awesome-icon icon="check"></font-awesome-icon> {{optional}}

            .mt-4
              .bold.subtitle(@click="showingDetails = !showingDetails")
                | Dettagli <font-awesome-icon v-if="showingDetails" icon = "chevron-down" /><font-awesome-icon v-if="!showingDetails" icon = "chevron-right" />
              .description-content.light(v-if="showingDetails").mt-4
                .details-list
                  dl.row
                    dt.col.title Anno
                    dt.col {{capitalize(vehicle.get("registration_year"))}}
                  dl.row
                    dt.col.title Trazione
                    dt.col {{capitalize(vehicle.get("traction"))}}
                  dl.row
                    dt.col.title Verniciatura
                    dt.col {{capitalize(vehicle.get("exterior_paint"))}}
                  dl.row
                    dt.col.title Colore interni
                    dt.col {{capitalize(vehicle.get("interior_color"))}}
                  dl.row
                    dt.col.title Materiali interni
                    dt.col {{capitalize(vehicle.get("interior_make"))}}
                  dl.row
                    dt.col.title Cambio
                    dt.col {{capitalize(vehicle.get("gearbox"))}}
                  dl.row
                    dt.col.title CC
                    dt.col {{capitalize(vehicle.get("cc"))}} cm³
                  dl.row
                    dt.col.title Kw/Cv
                    dt.col {{vehicle.get("kwatt")}} / {{calculateCv(vehicle.get("kwatt"))}}
                  dl.row
                    dt.col.title Cilindri
                    dt.col {{capitalize(vehicle.get("cylinders"))}} 
                  dl.row
                    dt.col.title Cavalli fiscali
                    dt.col {{capitalize(vehicle.get("cvfiscali"))}}
                  dl.row
                    dt.col.title Porte
                    dt.col {{capitalize(vehicle.get("doors"))}}
                  dl.row
                    dt.col.title Posti
                    dt.col {{capitalize(vehicle.get("seats"))}}
                  dl.row
                    dt.col.title Peso a vuoto
                    dt.col {{capitalize(vehicle.get("weight"))}} kg
                  dl.row
                    dt.col.title Classe di emissione
                    dt.col {{capitalize(vehicle.get("emission_class"))}}
                  dl.row
                    dt.col.title Consumi
                    dt.col {{capitalize(vehicle.get("consumption_urban"))}} Città / {{capitalize(vehicle.get("consumption_outer"))}} Autostrada
                  dl.row
                    dt.col.title Emissione CO2
                    dt.col {{capitalize(vehicle.get("emission_co2"))}}
                  dl.row
                    dt.col.title Danneggiata
                    dt.col {{vehicle.get("damaged") ? 'Si' : 'No'}}
                  dl.row
                    dt.col.title Veicolo industriale
                    dt.col {{vehicle.get("business_vehicle") ? 'Si' : 'No'}}
                  dl.row
                    dt.col.title Pronto consegna
                    dt.col {{vehicle.get("prompt_delivery") ? 'Si' : 'No'}}
                  dl.row.no-border
                    dt.col.title Garanzia
                    dt.col {{vehicle.get("warrenty") ? 'Si' : 'No'}}
        
        .mt-5
          h5.bold Potrebbero piacerti anche...
          .row
            VehicleBoxSmall(v-for="car in relatedCars", :vehicle="car").col-lg-4.col-sm-12.px-3.mt-4
      LightGallery(:images="galleryImages" :index="galleryIndex" :disable-scroll="true" @close="galleryIndex = null") 
</template>

<script>
import {currencyFormatter, getMainImage} from '../tools'
import Parse from 'parse'
const Vehicle = Parse.Object.extend('Vehicle')
import { LightGallery } from 'vue-light-gallery';
import {VehicleBoxSmall, Loader} from '../components'
import _ from 'lodash'
import {relatedCars} from './related_cars'
export default {
  name: 'VehicleDetail',
  components: {
    LightGallery,
    VehicleBoxSmall,
    Loader
  },
  props: {
    id: String
  },
  data(){
    return {
      galleryIndex: null,
      galleryImages: [],
      thumbImages: [],
      showingDescription: true,
      showingEquipment: false,
      showingDetails: false,
      vehicle: null,
      loading: false,
      relatedCars: []
    }
  },
  mounted(){
    this.loading = true
    this.loadVehicle()
  },
  methods: {
    loadVehicle(){
      let query = new Parse.Query(Vehicle)
      query.get(this.id).then(
        (vehicle)=>{
          this.vehicle = vehicle
          this.prepareImages()
          this.loading = false
          this.getRelatedCars();
        },
        (error) => {
          console.log(error)
          this.loading = false
        }
      )
    },
    euro(value){
      return currencyFormatter.format(parseFloat(value))
    },
    mainImage(vehicle){
      return getMainImage(vehicle, 'hd')
    },
    prepareImages(){
      let images = this.vehicle.get("images");
      images.forEach(image => {
        let galleryImage = {
          name: "",
          url: image['hd'].url()
        }
        let thumbImage = {
          name: "",
          url: image['medium'].url()
        }
        this.galleryImages.push(galleryImage)
        this.thumbImages.push(thumbImage)
      });
    },
    capitalize(str){
      return _.capitalize(str)
    },
    getMainInfos(vehicle){
      let infos = vehicle.get('additional_informations').split('\n');
      console.log(infos.length - infos.indexOf('\r'))
      infos.splice(infos.indexOf('\r'), infos.length - infos.indexOf('\r'));
      return infos;
    },
    getLastInfos(vehicle){
      let infos = vehicle.get('additional_informations').split('\n');
      infos.splice(0, infos.indexOf('\r'));
      return infos.filter(function(item){
        if(item.trim('') != "") return true;
      });
    },
    getOptionals(vehicle){
      let options = vehicle.get('options');
      return options.flat();
    },
    async getRelatedCars(){
      this.relatedCars = await relatedCars(this.vehicle)
      console.log(this.relatedCars)
    },
    calculateCv(kwatt){
      return Math.round(kwatt*1.36)
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../general.less';
    .two-cols{
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;

      @media only screen and (max-width: 450px){
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
      }

      li {
        padding: 2px;
      }
    }
    .subtitle {
      font-size: 18px;
      cursor: pointer;
    }
    
    .description-content {
      font-size: 18px;
    }

    .car-logo {
      font-size: 30px;
    }

    .small {
      font-weight: 800;
      color: gray;
    }

    .side-info {
      font-size: 20px;
    }
  
    .thumb {
      cursor: pointer;
      overflow: hidden;
    }
    .image-list-container {
      display: block;
      width: 100%;
      height: 120px;
      white-space: nowrap;
      overflow: auto;
      overflow: -moz-scrollbars-none;
      /* this will hide the scrollbar in internet explorers */
      -ms-overflow-style: none; 
    }
    .image-list-container::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
    .image-list-item {
      display: inline-block;
      background-position-x: center;
      background-size: cover;
      height: 100px;
      width: 180px;
      background-color: lightgray;
      margin-right: 10px
    }

    .img-fluid {
      background-color: lightgray;
      min-height: 450px;
      @media only screen and (max-width: 450px){
        min-height: 225px;        
      }
      background-size: cover;
      background-position-x: center;
      cursor: pointer;
    }

    .details-list {
      border:solid 1px gray;
      dl {
        margin:0;
        border-bottom:1px solid grey;
        dt {
          padding: 7px;
          font-weight: 200;
          background: rgb(251, 251, 251)
        }
        dt.title {
          font-weight: 400;
          background: rgb(199, 199, 199);
          max-width: 200px;
        }
      }
    }
    .no-border {
      border:none !important;
    }
</style>
