<template lang="pug">
    .vehicle-box-big
      carousel(ref="car" :perPage="1" :autoplay="true" :loop="true" :autoplayTimeout="5000")
        slide(v-for="vehicle in vehicles")
          .d-flex.justify-content-between
            .arrow.d-flex.align-items-center.d-none.d-md-flex
              .icon(@click="previousPage()").z-1 <font-awesome-icon icon = "chevron-left" /> 
            .item.flex-grow-1
              .image-container.w-100.d-flex.align-items-end.justify-content-end(:style="{backgroundImage: 'url('+mainImage(vehicle)+')'}")
                .box-description.m-5.px-4.py-3.d-none.d-lg-block
                  .vehicle-name.bold {{vehicle.get("make")}} {{vehicle.get("model")}} {{vehicle.get("version")}}
                  .row.mt-3
                    .col-6
                      .vehicle-info.regular.mt-1 
                        span.bold Mese/anno: 
                        span {{vehicle.get('registration_month')}}/{{vehicle.get('registration_year')}} 
                      .vehicle-info.regular.mt-1 
                        span.bold KM: 
                        span {{vehicle.get("km").toLocaleString('it-IT')}} 
                    .col-6
                      .vehicle-info.regular.mt-1 
                        span.bold CC: 
                        span {{vehicle.get('cc')}} 
                      .vehicle-info.regular.mt-1 
                        span.bold Cambio: 
                        span {{vehicle.get("gearbox")}} 
                  router-link(:to="{name:'vehicle', params:{id:vehicle.id}}").link-no-decoration
                    .cta.primary-button.mt-4
                      .bold.small.text-center SCOPRI SUBITO
              .box-description.mt-1.px-4.py-3.d-block.d-lg-none.black-bg
                  .vehicle-name.bold {{vehicle.get("make")}} {{vehicle.get("model")}} {{vehicle.get("version")}}
                  .row.mt-3
                    .col-6
                      .vehicle-info.regular.mt-1 
                        span.bold Mese/anno: 
                        span {{vehicle.get('registration_month')}}/{{vehicle.get('registration_year')}}
                      .vehicle-info.regular.mt-1 
                        span.bold KM: 
                        span {{vehicle.get("km").toLocaleString('it-IT')}} 
                    .col-6
                      .vehicle-info.regular.mt-1 
                        span.bold CC: 
                        span {{vehicle.get('cc')}} 
                      .vehicle-info.regular.mt-1 
                        span.bold Cambio: 
                        span {{vehicle.get("gearbox")}} 
                  router-link(:to="{name:'vehicle', params:{id:vehicle.id}}").link-no-decoration
                    .cta.primary-button.mt-4
                      .bold.small.text-center SCOPRI SUBITO 
            .arrow.d-flex.align-items-center.d-none.d-md-flex
                .icon(@click="nextPage()").z-1 <font-awesome-icon icon = "chevron-right" /> 
</template>

<script>
import {currencyFormatter, getMainImage} from '../../tools'
export default {
  name: 'VehicleBoxBig',
  props:{
    vehicles: Array
  },
  mounted(){
    console.log(this.vehicles)
  },
  methods:{
    euro(value){
      return currencyFormatter.format(parseFloat(value))
    },
    mainImage(vehicle){
      return getMainImage(vehicle, 'hd')
    },
    nextPage(){
      this.$refs.car.handleNavigation('forward');
    },
    previousPage(){
      this.$refs.car.handleNavigation('backward');
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../../general.less';
    .image-container {
      height: 36vw;
      @media only screen and (max-width: 450px){
        height: 50vw;
      }
      background-color: lightgrey;
      background-position: center;
      background-size: cover;
    }

    .box-description { 
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      text-align: left;
      .vehicle-name {
        font-size: 24px;
      }
      .vehicle-info {
        font-size: 20px;
      }
      .vehicle-price {
        font-size: 24px;
      }
    }

    .black-bg {
      background-color: rgba(0, 0, 0, 1) !important;
    }
    .arrow {
      // background-color: @primary;
      padding:10px;
      color: black;
      cursor: pointer;
    }
</style>
