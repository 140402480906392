<template lang="pug">
    .thankyou
        .container
            .row.py-5.mt-3
                .col-12.text-center
                    h2.bold Grazie per averci contattato!
                    h5.mt-3 Il tuo messaggio è stato correttamente inviato, ti risponderemo appena possibile!
</template>
<script>
export default {
  name: 'ThankYou',
  components: {
  }
}
</script>

<style lang="less" scoped>
    h5 {
        opacity: 0.7
    }
</style>
