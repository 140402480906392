<template lang="pug">
    .notfound
        h3 Not Found

</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style lang="less" scoped>
    @import '../general.less';
</style>
