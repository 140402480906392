<template lang="pug">
    .sell.container
        .row.py-5.mt-3
            .col-12.col-md-4.mt-2
                h4.bold Vendi la tua auto
                p Okay Cars acquista autoveicoli di ogni Marca/Modello direttamente da privati o Società.
                p Ci occupiamo, nell’eventualità, di estinguere e riscattare direttamente le auto da finanziamenti o Leasing, e tutte le pratiche burocratiche di trasferimento di proprietà verranno regolarmente e scrupolosamente eseguite presso le agenzie competenti.
                p Ovviamente il pagamento è immediato una volta trovato l’accordo commerciale più conveniente per entrambe le parti.
            .col-0.col-md-1.mt-2
            .col-12.col-md-7.mt-2(v-if="!success")
                h5.bold Inserisci le informazioni
                p Maggiori dettagli ci permettono di dare una valutazione precisa e attendibile! Ti contatteremo al più presto!

                form.form
                    .form-group.row
                        .col-12.col-md-6
                            label.form-label(for="name") Nome *
                            input.form-control(name="name" v-model="firstName" type="text")
                        .col-12.col-md-6
                            label.form-label(for="cognome") Cognome *
                            input.form-control(name="cognome" v-model="lastName" type="text")
                    .form-group.row.mt-2
                        .col-12.col-md-6
                            label.form-label(for="name") Email *
                            input.form-control(name="name" v-model="email" type="text")
                        .col-12.col-md-6
                            label.form-label(for="cognome") Telefono *
                            input.form-control(name="cognome" v-model="phone" type="text")
                    .form-group.row.mt-2
                        .col-12.col-md-6
                            label.form-label(for="name") Marca *
                            input.form-control(name="name" v-model="make" type="text")
                        .col-12.col-md-6
                            label.form-label(for="modello") Modello *
                            input.form-control(name="modello" v-model="model" type="text")
                    .form-group.row.mt-2
                        .col-12.col-md-6
                            label.form-label(for="anno") Anno *
                            input.form-control(name="anno" v-model="year" type="text")
                        .col-12.col-md-6
                            label.form-label(for="colore") Colore *
                            input.form-control(name="colore" v-model="color" type="text")
                    .form-group.row.mt-2
                        .col-12.col-md-6
                            label.form-label(for="carrozzeria") Carrozzeria *
                            input.form-control(name="carrozzeria" v-model="exterior" type="text")
                        .col-12.col-md-6
                            label.form-label(for="km") KM *
                            input.form-control(name="km" v-model="km" type="text")
                    .form-group.row.mt-2
                        .col-12.col-md-6
                            label.form-label(for="importo") Importo richiesto *
                            input.form-control(name="importo" v-model="value" type="text")
                        .col-12.col-md-6
                            label.form-label(for="modello") Note
                            input.form-control(name="modello" v-model="notes" type="text")
                    .mt-5 
                        input(type="checkbox" v-model="checkPrivacy") 
                        span.mx-2 Dichiaro di aver letto 
                            router-link(to="privacy-policy" target="_blank") l'informativa
                            |  e, con specifico riferimento alle finalità, presto consenso al trattamento dei dati
                    .d-flex.justify-content-start.mt-4
                        button.primary-button.text-center(@click="sendData" :disabled="!checkPrivacy" :class="!checkPrivacy ? 'opacity-50' : ''")
                            | Invia richiesta
                    p.small-text.error.mt-2(v-if="error") {{error}}
            .col-12.col-md-7.mt-2(v-if="success")
                .success {{success}}
</template>

<script>
import Parse from 'parse'
const Sell = Parse.Object.extend('Sell');
export default {
  name: 'Sell',
  data(){
      return {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          make: '',
          model: '',
          year: '',
          color: '',
          exterior: '',
          km: '',
          value: '',
          notes: '',
          error: null,
          success: null,
          checkPrivacy: false
      }
  },
  mounted(){

  },
  methods: {
      sendData(e){
          e.preventDefault()
          
          if(!this.validate()){
              this.error = "I campi contrassegnati con '*' sono obbligatori."
              return
          }

          let sell = new Sell();
          sell.set('firstName', this.firstName);
          sell.set('lastName', this.lastName);
          sell.set('email', this.email);
          sell.set('phone', this.phone);
          sell.set('make', this.make);
          sell.set('model', this.model);
          sell.set('year', this.year);
          sell.set('color', this.color);
          sell.set('exterior', this.exterior);
          sell.set('km', this.km);
          sell.set('value', this.value);
          sell.set('notes', this.notes);

          sell.save()
          .then(() => {
              this.success = "La tua auto è stata inserita. Riceverai a breve un nostro contatto! Grazie dallo staff di OkayCars."

              // Call Parse Job
              
          })
      },
      validate(){
          return (this.firstName != '' && this.lastName != '' && this.email != '' && this.validateEmail(this.email) && this.phone != '' && this.make != '' && this.model != '' && this.year != '' && this.exterior != '' && this.color != '' && this.km != '' && this.value != '')
      },
      validateEmail(email) {
          const re = /\S+@\S+\.\S+/;
          return re.test(email);
      }

  }
}
</script>

<style lang="less" scoped>
    @import '../general.less';
    .error {
        color: red
    }
    .success {
        font-weight: bold;
        font-size: 19px;
    }
</style>
