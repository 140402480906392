<template lang="pug">
    .configurator          
</template>

<script>
export default {
  name: 'Configurator'
}
</script>

<style lang="less" scoped>
    @import '../../general.less';
</style>
