<template lang="pug">
  .loader
    //- |Loading
    |Caricamento in corso... 
    img(:src="getLoaderImage()" width="60" height="60")
            
</template>

<script>
export default {
  name: 'Loader',
  methods: {
    getLoaderImage(){
      return require('@/assets/loader.svg')
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../../general.less';
    .loader {
        width: 100%;
        background-color: rgba(255,255,255,0.9);
    }
</style>
