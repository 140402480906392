<template lang="pug">
  nav.navbar.navbar-expand-lg
    .container-fluid
        router-link(:to='{name: "home"}').navbar-brand 
            img(:src="require('@/assets/logo.png')")
        //- button.navbar-toggler(type='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation')
        //-     span.navbar-toggler-icon
        button.hamburger(@click="menuTapped()").navbar-toggler
            font-awesome-icon(icon="bars")
        #navbarSupportedContent.collapse.navbar-collapse
            ul.navbar-nav.ms-auto.mb-2.mb-lg-0.align-items-center
                li.nav-item
                    router-link(:to='{name: "home"}').nav-link Vetrina
                li.nav-item
                    router-link(:to='{name: "search"}').nav-link Catalogo
                li.nav-item
                    router-link(:to='{name: "sell"}').nav-link Vendi
                li.nav-item
                    router-link(:to='{name: "who"}').nav-link Chi siamo
                li.nav-item
                    router-link(:to='{name: "contacts"}').nav-link
                        .primary-button Richiedi informazioni
</template>

<script>
export default {
  name: 'Navigation',
  methods: {
      menuTapped(){
          this.$emit('togglemenu')
      }
  }
}
</script>

<style lang="less" scoped>
    @import '../../general.less';
    nav {
        background: @primary;
        color: @white;
        a {
            color: @white;
        }
        height: @navbar-height;
    }
    
    .hamburger {
        background: transparent;
        border: none;
        color: white;
    }

    .navbar-brand {
        img {
            max-height: 63px;
        }
    }
</style>
