import Parse from 'parse'
const Vehicle = Parse.Object.extend('Vehicle')
import _ from 'lodash'
const relatedCars = async (vehicle) => {
    let results = [];
    let foundIds = [];

    // Category
    let queryType = new Parse.Query(Vehicle)
    queryType.equalTo('body', vehicle.get('body'));
    
    // Type (car, moto, camper)
    let queryCar = new Parse.Query(Vehicle)
    queryCar.equalTo('type', vehicle.get('type'));

    // CC
    let queryCC = new Parse.Query(Vehicle)
    let scostamentoCC = (20*vehicle.get('cc')/100)
    let minCC = vehicle.get('cc') - scostamentoCC
    let maxCC= vehicle.get('cc') + scostamentoCC
    queryCC.lessThanOrEqualTo('cc', maxCC);
    queryCC.greaterThanOrEqualTo('cc', minCC);

    // Price
    let queryPrice = new Parse.Query(Vehicle)
    let scostamento = (20*vehicle.get('customers_price')/100)
    let minPrice = vehicle.get('customers_price') - scostamento
    let maxPrice = vehicle.get('customers_price') + scostamento
    queryPrice.lessThanOrEqualTo('customers_price', maxPrice);
    queryPrice.greaterThanOrEqualTo('customers_price', minPrice);

    // Make (brand)
    let queryMake = new Parse.Query(Vehicle)
    queryMake.equalTo('make', vehicle.get('make'));

    let queryModel = new Parse.Query(Vehicle)
    queryModel.equalTo('model', vehicle.get('model'));

    const mainQuery = Parse.Query.and(
        Parse.Query.or(queryMake, queryModel),
        queryPrice,
        queryCar,
        queryType,
      );
    mainQuery.limit(3)
    mainQuery.notEqualTo('objectId', vehicle.id)
    let mainFoundSet = await mainQuery.find();
    mainFoundSet.forEach((found) => {
        results.push(found)
        foundIds.push(found.id)
    })
    console.log("Found on 0", mainFoundSet)
    if(results.length > 2){
        return exporter(results)
    }
    
    const reducedQ1 = Parse.Query.and(
        queryMake,
        queryPrice,
        queryCar,
        queryType,
    );
    reducedQ1.limit(3)
    reducedQ1.notEqualTo('objectId', vehicle.id)
    reducedQ1.notContainedIn('objectId', foundIds)
    let q1FoundSet = await reducedQ1.find();
    q1FoundSet.forEach((found) => {
        results.push(found)
        foundIds.push(found.id)
    })
    console.log("Found on 1", q1FoundSet)
    if(results.length > 2){
        return exporter(results)
    }

    const reducedQ2 = Parse.Query.and(
        queryPrice,
        queryType,
        queryCar,
    );
    reducedQ2.limit(3)
    reducedQ2.notEqualTo('objectId', vehicle.id)
    reducedQ2.notContainedIn('objectId', foundIds)
    let q2FoundSet = await reducedQ2.find();
    q2FoundSet.forEach((found) => {
        results.push(found)
        foundIds.push(found.id)
    })
    console.log("Found on 2", q2FoundSet)
    if(results.length > 2){
        return exporter(results)
    }

    const reducedQ3 = Parse.Query.and(
        queryPrice,
        queryCar,
    );
    reducedQ3.notEqualTo('objectId', vehicle.id)
    reducedQ3.notContainedIn('objectId', foundIds)
    let q3FoundSet = await reducedQ3.find();
    q3FoundSet.forEach((found) => {
        results.push(found)
        foundIds.push(found.id)
    })
    console.log("Found on 3", q3FoundSet)
    if(results.length > 2){
        return exporter(results)
    }

    const reducedQ4 = Parse.Query.and(
        queryCar,
    );
    reducedQ4.notEqualTo('objectId', vehicle.id)
    reducedQ4.notContainedIn('objectId', foundIds)
    let q4FoundSet = await reducedQ4.find();
    q4FoundSet.forEach((found) => {
        results.push(found)
        foundIds.push(found.id)
    })
    console.log("Found on 4", q4FoundSet)
    if(results.length > 2){
        return exporter(results)
    }
}

function exporter(results){
    return _.chunk(results, 3)[0]
}

export {
    relatedCars
}