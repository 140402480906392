import Parse from 'parse'
Parse.initialize(process.env.VUE_APP_PARSE_APP_ID, process.env.VUE_APP_PARSE_JS_KEY);
Parse.serverURL = process.env.VUE_APP_PARSE_SERVER

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronDown, faChevronRight, faChevronLeft, faBars, faTimes, faCheck , faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'

library.add([faChevronDown, faChevronRight, faBars, faTimes, faCheck, faChevronLeft, faPhone, faEnvelope]);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

import 'bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-range-component/dist/vue-range-slider.css'
import 'wow.js/css/libs/animate.css' 
import 'car-makes-icons/dist/style.css';
import WOW from 'wow.js'

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

new WOW().init()

new Vue({
  el: '#app',
  router,
  template: '<App/>',
  render: h => h(App)
})